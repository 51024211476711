import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../loader';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(
        private http: HttpClient,
        private _loader:LoaderService
    ) { }

    post(url:string, data:any, loader = true, responseType?:string) {
        if(loader) {
          this._loader.markAsLoading();
        }
        if(responseType) {
          return this.http.post(url, data, {responseType: responseType as 'json'});
        } else {
          return this.http.post(url, data);
        }
    }

    put(url:string, data:any, loader = true) {
      if(loader) {
        this._loader.markAsLoading();
      }
        return this.http.put(url, data);
    }

    patch(url:string, data:any, loader = false){
      if(loader) {
        this._loader.markAsLoading();
      }
        return this.http.patch(url, data);
    }
    delete(url:string, httpParams:any, loader = false) {
      if(loader) {
        this._loader.markAsLoading();
      }
        for (let item in httpParams) {
            if (httpParams[item] === '' || httpParams[item] === undefined || httpParams[item] === null /* || httpParams[item] === [] */) {
                delete httpParams[item];
            }
        }
        const header:any = {};
        if (httpParams) {
            header['body'] = httpParams;
        }
        return this.http.delete(url, {...header});
    }
    get(url: string, params?: any,loader=true, responseType?: string): Observable<any> {
      if(loader) {
        this._loader.markAsLoading();
      }
      if(responseType) {
        return this.http.get<any>(url, { params: params, responseType: responseType as 'json' });
      }else {
        return this.http.get<any>(url, { params: params });

      }
    }
}
