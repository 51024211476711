import { PATTERN } from "./pattern.constants";

export const MESSAGES = {
    ERROR: {
      $401: "Your session is expired, please login again.",
      $404: "Resource not found",
      $500: "Internal Server Error",
      $504: "Slow internet connection",
      $410: "User not found",
      $503: 'Service unavailable',
      UNKNOWN: 'Something went wrong, Please try again later.'
    },
    NAVIGATION: {
      NOT_FOUND: "Navigation error occured"
    },
    OFFLINE: "You are offline, please connect to internet and retry",
  }

  export const OTP_SENT = 'OTP sent successfully. Please check your email!';
  export const PATTERN_ERRORS = (pattern:any,key:any) => {
    if(pattern == PATTERN.email){
        return `Please enter a valid ${key.toLowerCase()}`
    }
    if(pattern == PATTERN.password){
        return `Please enter an password with at least 8 characters, one upper case, one lower case letter and one number.`
    }
    if(pattern == PATTERN.name){
        return `Please enter a valid ${key.toLowerCase()}`
    }
    if(pattern == PATTERN.phone){
        return `${toTitleCase(key)} must contain only digits`
    }
    if(pattern == PATTERN.price){
        return `${toTitleCase(key)} must be numeric`
    }
    if(pattern == PATTERN.audioLink){
        return `${toTitleCase(key)} must be the audio file Url`
    }
    if(pattern == PATTERN.code) {
        return `${toTitleCase(key)} is invalid`
    }
    return null
}

export const toTitleCase = (str:any) => {
  return str.replace(
      /\w\S*/g,
      function (txt:any) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
  );
}

export const VALIDATE_TIME_SLOT = 'Please Select a valid time slot';
export const FILE_DOWNLOAD_SUCCESSFULLY = 'File downloaded Successfully'
export const FILE_UPLOAD_SUCCESSFULLY = 'File uploaded Successfully'