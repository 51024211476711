import { environment } from "@environment";

export const API_URL = {
    conferencesList:`~/cms/conferences`,
    registrationfields:  `~/cms/registration`,
    login: '^/cms/Login',
    validateOtp: '^/cms/ValidateOTP',
    likeDislike: '~/cms/interest',
    getRole: '~/cms/Role',
    getAccount: '~/cms/Account',
    saveRegistration: '~/cms/SaveRegistration',
    downloadFile: '~/cms/DownloadTemplate',
    uploadFile: '~/cms/ImportPreferences',
    eventData: '^/cms/Conference/Event',
    googlePlace : (placeId:string) => `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=photos&key=${environment.googleApiKey}`,
    userDetails: '~/cms/Contact/Detail'
}